import "./CricketStats.css";
import Card from "../../../Card";
import {
  ORGANIZATION_STATS,
  ORGANIZATION_AWARDS,
} from "../../../../api/organization";
import { useQuery } from "@apollo/client";
// import OrganizationFormStats from "../../../OrganizationFormStats";
import StatPercentage from "../StatPercentage";
// import OrganizationAwards from "../OrganizationAwards/OrganizationAwards";
import captureException from "../../../../utils/captureException";
import { Fragment } from "react";
import Square from "../../../../mobileComponents/Square";
import Avatar from "../../../Avatar";
import moment from "moment";
import VerticalText from "../../../../mobileComponents/VerticalText";

function OrganizationRecordStats({ total, wins, draws, losses }) {
  return (
    <div className="organization-record">
      <div className="organization-record__meta">
        <div className="organization-record__meta__title">RECORD</div>

        <div className="organization-record__meta__highlight">
          <div className="organization-record__meta__highlight__value">
            {total}
          </div>
          <div className="organization-record__meta__highlight__label">
            Games Played
          </div>
        </div>
      </div>

      <div className="organization-record__items">
        <div
          className="organization-record__items__item"
          style={{ marginTop: "8px" }}
        >
          <div className="organization-record__items__item__label">Win</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (wins / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{wins}</div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="organization-record__items__item__label">Loss</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (losses / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">
            {losses}
          </div>
        </div>

        <div
          className="organization-record__items__item"
          style={{ marginBottom: "8px" }}
        >
          <div className="organization-record__items__item__label">Draw</div>

          <div className="organization-record__items__item__graph">
            <div className="organization-record__items__item__graph__track">
              <div
                className="organization-record__items__item__graph__fill"
                style={{ width: total ? (draws / total) * 100 + "%" : "1%" }}
              ></div>
            </div>
          </div>

          <div className="organization-record__items__item__value">{draws}</div>
        </div>
      </div>
    </div>
  );
}

function OrganizationBattingStats({ runsScored, ballsFaced, strikeRate }) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">BATTING</div>
      <div
        className="profile-attack-stats__items"
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 6,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {runsScored}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Runs
                <br /> Scored
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {ballsFaced}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Balls <br />
                Faced
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {strikeRate}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Strike
                <br /> Rate
              </div>
            </div>
          )}
        </Square>
      </div>
      <div
        className="profile-fielding-stats__graphic"
        style={{ gridColumnStart: 1, gridColumnEnd: 6 }}
      >
        <div className="profile-fielding-stats__graphic__text green">
          BATTING
        </div>
        <div className="profile-fielding-stats__graphic__text blue">
          BATTING
        </div>
      </div>
    </div>
  );
}

function OrganizationBowlingStats({ wicketsTaken, ballsBowled, economyRate }) {
  return (
    <div className="profile-attack-stats">
      <div className="profile-attack-stats__title">BATTING</div>
      <div
        className="profile-attack-stats__items"
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 6,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {wicketsTaken}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Wickets
                <br /> Taken
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                borderRight: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {ballsBowled}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Balls
                <br /> Bowled
              </div>
            </div>
          )}
        </Square>

        <Square>
          {({ squareRef, dimensions }) => (
            <div
              className="profile-attack-stats__items__item"
              style={{
                height: dimensions.width + "px",
                borderBottom: "solid 1px var(--light-2)",
                width: "33%",
              }}
              ref={squareRef}
            >
              <div className="profile-attack-stats__items__item__value">
                {economyRate}
              </div>
              <div className="profile-attack-stats__items__item__label">
                Economy
                <br /> Rate
              </div>
            </div>
          )}
        </Square>
      </div>
      <div
        className="profile-fielding-stats__graphic"
        style={{ gridColumnStart: 1, gridColumnEnd: 6 }}
      >
        <div className="profile-fielding-stats__graphic__text green">
          BOWLING
        </div>
        <div className="profile-fielding-stats__graphic__text blue">
          BOWLING
        </div>
      </div>
    </div>
  );
}

function OrganizationFormStats({ id, form }) {
  // const formFixtures = form.filter((f) =>
  //   f.roles.find((r) => r.organization.id !== id)
  // );

  // console.log("Form:", form);

  return (
    <div className="organization-form">
      <div className="organization-form__title">FORM</div>

      <div className="organization-form__items">
        {!form?.length && (
          <div className="no-data">No previous matches found</div>
        )}
        {form?.map((fixture) => (
          <FormItem id={id} fixture={fixture} />
        ))}
      </div>
    </div>
  );
}

function FormItem({ id, fixture }) {
  const orgPlacement = fixture.placements?.find(
    (p) => p.organization.id === id
  );
  const opponentPlacement = fixture.placements?.find(
    (p) => p.organization.id !== id
  );

  const opponentRole = fixture.roles.find((r) => r.organization.id !== id);
  const result =
    (orgPlacement?.value || 0) > (opponentPlacement?.value || 0)
      ? "W"
      : (orgPlacement?.value || 0) < (opponentPlacement?.value || 0)
      ? "L"
      : "D";

  return (
    <div className="organization-form__items__item">
      <div className="organization-form__items__item__meta">
        <div className="organization-form__items__item__meta__image">
          {opponentRole?.organization?.profilePhoto && (
            <Avatar media={opponentRole.organization.profilePhoto} />
          )}
        </div>

        <div className="organization-form__items__item__meta__text">
          <div className="organization-form__items__item__meta__text__primary">
            {opponentRole?.organization?.name}
          </div>

          <div className="organization-form__items__item__meta__text__secondary">
            {fixture.scheduledStart
              ? moment(fixture.scheduledStart).format("Do MMM YYYY")
              : "-"}
          </div>
        </div>
      </div>

      <div className="organization-form__items__item__result">
        <div
          className={
            "organization-form__items__item__result__text " +
            (result === "W" ? "green" : result === "L" ? "red" : "orange")
          }
        >
          {result === "W" ? "WIN" : result === "L" ? "LOSS" : "DRAW"}
        </div>

        {/* <div className="organization-form__items__item__result__score">
            {orgPlacement?.value || 0} - {opponentPlacement?.value || 0}
          </div> */}
      </div>
    </div>
  );
}

function OrganizationAwards({ id, sport }) {
  const { loading, error, data } = useQuery(ORGANIZATION_AWARDS, {
    variables: {
      id,
      sport,
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.CricketStats.OrganizationAwards",
        query: "ORGANIZATION_AWARDS",
      },
    });
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const organization = data.organization;
  const placements = organization?.placements;
  const groupedPlacements = placements?.reduce((accum, curr) => {
    if (!accum[curr.awardType?.id]) {
      accum[curr.awardType.id] = {
        awardType: curr.awardType,
        event: curr.awardType.event,
        placements: [curr],
      };
    } else {
      accum[curr.awardType?.id].placements.push(curr);
    }

    return accum;
  }, {});

  // console.log("AWARDS DATA:", data);
  return (
    <div className="profile-awards-stats">
      <div className="profile-awards-stats__title">AWARDS</div>

      {!placements.length && (
        <div className="profile-awards-stats__items">
          <div className="no-data">No awards for this team yet.</div>
        </div>
      )}

      <div className="profile-awards-stats__items">
        {Object.keys(groupedPlacements).map((key) => {
          const item = groupedPlacements[key];
          return (
            <div className="profile-awards-stats__items__item">
              <div className="profile-awards-stats__items__item__img"></div>
              <div className="profile-awards-stats__items__item__desc">
                <div className="profile-awards-stats__items__item__desc__primary">
                  {item.event.name}
                </div>
                <div className="profile-awards-stats__items__item__desc__secondary">
                  {item.awardType.name}{" "}
                  {item.placements.length > 1
                    ? "x " + item.placements.length
                    : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CricketStats({ id, sport, events }) {
  const { error, data } = useQuery(ORGANIZATION_STATS, {
    variables: {
      id,
      sport: sport || "Cricket",
      statTypes: [
        "Runs Scored",
        "Runs Conceded",
        "Balls Faced",
        "Balls Bowled",
        "Wicket Taken",
      ],
      events,
      rolesWhereInput: { type: { name: "PARTICIPANT" } },
    },
  });

  if (error) {
    captureException({
      error,
      info: {
        type: "query",
        component: "Organization.CricketStats",
        query: "ORGANIZATION_STATS",
      },
    });
    console.log(error);
  }

  // console.log("STATS:", data);

  const { totalGamesPlayed, form } = data?.organization?.stats || {};

  const record = JSON.parse(data?.organization?.stats?.record?.data || "{}");
  const aggregationsData = JSON.parse(
    data?.organization?.stats?.aggregations?.data || "{}"
  );

  return (
    <Fragment>
      <OrganizationRecordStats
        total={totalGamesPlayed}
        wins={record.wins}
        losses={record.losses}
        draws={record.draws}
      />

      <div className="profile-stats-grid">
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <OrganizationBattingStats
            runsScored={aggregationsData["Runs Scored"] || 0}
            ballsFaced={aggregationsData["Balls Faced"] || 0}
            strikeRate={Math.floor(
              (aggregationsData["Runs Scored"] /
                aggregationsData["Balls Faced"]) *
                100
            )}
          />

          <OrganizationBowlingStats
            wicketsTaken={aggregationsData["Wicket Taken"] || 0}
            ballsBowled={aggregationsData["Balls Bowled"] || 0}
            economyRate={
              Math.floor(
                (aggregationsData["Runs Conceded"] /
                  (Math.floor(aggregationsData["Balls Bowled"] / 6) +
                    (aggregationsData["Balls Bowled"] % 6) / 6)) *
                  100
              ) / 100 || 0
            }
          />
        </div>

        <OrganizationFormStats id={id} form={form} />
      </div>

      <OrganizationAwards id={id} sport={"Cricket"} />
    </Fragment>
  );
}

export default CricketStats;
