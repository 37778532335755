import "./CurrentOver.css";
import NewDropdown from "../../../components/NewDropdown";
import Card from "../../../components/Card";
import { ReactComponent as ChevronDown } from "../../../icons/chevron-down.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Spinner from "../../../components/Spinner";
import { useCricketScoringState } from "../utils/useCricketScoringState";
import { useQuery, useMutation, gql } from "@apollo/client";
import { FIXTURE } from "../../../api/functions";
import { CREATE_CRICKET_STAT } from "../../../api/fixture";
import extraAbbrevs from "../utils/extraAbbrevs";
import SelectPlayer from "../SelectPlayer";
import ballsCompleted from "../utils/ballsCompleted";
import SelectDismissedBatsman from "../SelectDismissedBatsman";
import captureException from "../../../utils/captureException";
import ErrorNotification from "../ErrorNotification";
import makeStat from "../utils/makeStat";
import { client } from "../../../utils/client";

function SelectRunOutType() {
  const [{ setOverlay, bowlingTeam }, setCricketScoringState] =
    useCricketScoringState();
  const dismiss = () => {
    // setCricketScoringState({
    //   selectedFielder: "",
    //   dismissedBatsman: "",
    //   secondaryFielder: "",
    // });
    setOverlay(false);
  };
  return (
    <div className="ScoringApp-cricket__scoring__modal-form">
      <div className="ScoringApp-cricket__scoring__modal-form__title">
        Select Run Out Type
        <div
          className="ScoringApp-cricket__scoring__modal-form__title__dismiss"
          onClick={dismiss}
        >
          <TimesIcon style={{ stroke: "var(--light-2", height: "16px" }} />
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__modal-form__list">
        <div
          className="ScoringApp-cricket__scoring__modal-form__list__item"
          onClick={() => {
            // onSelect(r);
            //   dismiss();
            setOverlay(
              <SelectPlayer
                title="Select Fielder"
                defaultTeam={bowlingTeam}
                onSelect={(player) => {
                  setCricketScoringState({
                    selectedFielder: player.profile,
                  });

                  setOverlay(
                    <SelectDismissedBatsman
                      onSelect={(player) => {
                        setCricketScoringState({
                          dismissedBatsman: player.profile,
                          selectedWicket: "Run Out",
                        });
                        setOverlay(false);
                      }}
                    />
                  );
                }}
                hideTabs={true}
              />
            );
          }}
        >
          <div className="ScoringApp-cricket__scoring__modal-form__list__item__name">
            <div className="ScoringApp-cricket__scoring__modal-form__list__item__name__primary">
              Direct
            </div>
          </div>
        </div>

        <div
          className="ScoringApp-cricket__scoring__modal-form__list__item"
          onClick={() => {
            // onSelect(r);
            //   dismiss();
            setOverlay(
              <SelectPlayer
                title="Select Fielder"
                defaultTeam={bowlingTeam}
                onSelect={(fielder) => {
                  setCricketScoringState({
                    selectedFielder: fielder.profile,
                  });

                  setOverlay(
                    <SelectPlayer
                      title="Select Stumper"
                      defaultTeam={bowlingTeam}
                      onSelect={(stumper) => {
                        setCricketScoringState({
                          secondaryFielder: stumper.profile,
                        });

                        setOverlay(
                          <SelectDismissedBatsman
                            onSelect={(player) => {
                              setCricketScoringState({
                                dismissedBatsman: player.profile,
                                selectedWicket: "Run Out",
                              });
                              setOverlay(false);
                            }}
                          />
                        );
                      }}
                      hideTabs={true}
                    />
                  );
                }}
                hideTabs={true}
              />
            );
          }}
        >
          <div className="ScoringApp-cricket__scoring__modal-form__list__item__name">
            <div className="ScoringApp-cricket__scoring__modal-form__list__item__name__primary">
              Indirect
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RunContainer({ value, currentBallLabel, cricketLiveScoring }) {
  const [
    {
      batsmen,
      bowler,
      strikeIndex,
      selectedExtras,
      selectedWicket,
      eventId,
      fixtureId,
      selectedPeriod,
      battingTeam,
      bowlingTeam,
      statTypes,
      currentOverNumber,
      selectedFielder,
      secondaryFielder,
      dismissedBatsman,
      setOverlay,
      bowlingSummaries = [],
      cache: cachedState,
      updateCache,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const [createCricketStat, { loading }] = useMutation(CREATE_CRICKET_STAT, {
    update: async (cache, { data: { createCricketStat } }) => {
      cache.modify({
        id: cache.identify(cricketLiveScoring),
        fields: {
          overStats(existingRefs, { readField }) {
            const newRef = cache.writeFragment({
              data: createCricketStat,
              fragment: gql`
                fragment NewOverItem on OverItem {
                  id
                  over
                  ballsBowled {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  ballsFaced {
                    id
                  }
                  runsScored {
                    id
                    value
                    profile {
                      id
                      name
                    }
                    organization {
                      id
                      name
                    }
                  }
                  runsConceded {
                    id
                    value
                    profile {
                      id
                      name
                    }
                  }
                  wicket {
                    id
                    statType {
                      id
                      name
                    }
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  wicketTaken {
                    id
                    profile {
                      id
                    }
                    organization {
                      id
                    }
                  }
                  extras {
                    id
                    statType {
                      id
                      name
                    }
                    value
                  }
                  fielding {
                    id
                    profile {
                      id
                    }
                  }
                }
              `,
            });

            return [...existingRefs, newRef];
          },
        },
      });

      console.log("RETURNED OVER ITEM:", createCricketStat);

      cache.modify({
        id: "CricketBattingSummaryItem:" + batsmen[strikeIndex].profile.id,
        fields: {
          runs(currentRuns) {
            return currentRuns + createCricketStat.runsScored.value;
          },
          balls(currentBalls) {
            const isWide = createCricketStat.extras?.find(
              (x) => x.statType.name === "Wide"
            );

            if (isWide) {
              return currentBalls;
            } else {
              return currentBalls + 1;
            }
          },
          fours(currentFours) {
            if (createCricketStat.runsScored.value === 4) {
              return currentFours + 1;
            } else {
              return currentFours;
            }
          },
          sixes(currentSixes) {
            if (createCricketStat.runsScored.value === 6) {
              return currentSixes + 1;
            } else {
              return currentSixes;
            }
          },
        },
      });

      cache.modify({
        id: "CricketBowlingSummaryItem:" + bowler.profile.id,
        fields: {
          runs(currentRuns) {
            return currentRuns + createCricketStat.runsConceded.value;
          },
          balls(currentBalls) {
            const isWide = createCricketStat.extras?.find(
              (x) => x.statType.name === "Wide"
            );

            const isNoBall = createCricketStat.extras?.find(
              (x) => x.statType.name === "No Ball"
            );

            if (isWide || isNoBall) {
              return currentBalls;
            } else {
              return currentBalls + 1;
            }
          },
          wickets(currentWickets) {
            if (createCricketStat.wicketTaken) {
              return currentWickets + 1;
            } else {
              return currentWickets;
            }
          },
          extras(currentExtras) {
            if (createCricketStat.extras.length) {
              const extrasValue = createCricketStat.extras.reduce(
                (accum, curr) => {
                  accum = accum + curr.value;
                  return accum;
                },
                0
              );
              return currentExtras + extrasValue;
            } else {
              return currentExtras;
            }
          },
        },
      });

      cache.modify({
        id: "CricketLiveScoreItem:" + battingTeam.id,
        fields: {
          runs(currentRuns) {
            const r = createCricketStat.runsScored.value || 0;
            const e =
              createCricketStat.extras?.reduce((accum, curr) => {
                accum = accum + curr.value;
                return accum;
              }, 0) || 0;

            return currentRuns + r + e;
          },
          wickets(currentWickets) {
            if (
              createCricketStat.wicket &&
              createCricketStat.wicket.statType.name !== "Retired Hurt"
            ) {
              return currentWickets + 1;
            } else {
              return currentWickets;
            }
          },
          overs(currentOvers) {
            const isWide = createCricketStat.extras?.find(
              (x) => x.statType.name === "Wide"
            );

            const isNoBall = createCricketStat.extras?.find(
              (x) => x.statType.name === "No Ball"
            );

            if (isWide || isNoBall) {
              return currentOvers;
            }

            if (createCricketStat.ballsBowled.value > currentOvers) {
              return createCricketStat.ballsBowled.value;
            } else {
              return currentOvers;
            }
          },
        },
      });

      // console.log("Modifying period cache:", selectedPeriod.id);
      // Update the innings score & wickets
      const updatedPeriodScores = await client.query({
        query: FIXTURE({
          selectionSet: `                    
          cricketLiveScoring{
            id
            periodScores{
              id
              runs
              wickets
              overs
            }        
          }
      `,
        }),
        variables: {
          id: fixtureId,
        },
        fetchPolicy: "network-only",
      });
      // console.log("Updated period score:", updatedPeriodScores);
      // Update period scores
      cache.modify({
        id: "CricketLiveScoringStats:" + fixtureId,
        fields: {
          periodScores(current) {
            return updatedPeriodScores.data.fixture.cricketLiveScoring
              .periodScores;
          },
        },
      });

      // cache.modify({
      //   id: "CricketPeriodScoreItem:" + selectedPeriod.id,
      //   fields: {
      //     runs(currentRuns) {
      //       const r = createCricketStat.runsScored.value || 0;
      //       const e =
      //         createCricketStat.extras?.reduce((accum, curr) => {
      //           accum = accum + curr.value;
      //           return accum;
      //         }, 0) || 0;

      //       return currentRuns + r + e;
      //     },
      //     wickets(currentWickets) {
      //       if (
      //         createCricketStat.wicket &&
      //         createCricketStat.wicket.statType.name !== "Retired Hurt"
      //       ) {
      //         return currentWickets + 1;
      //       } else {
      //         return currentWickets;
      //       }
      //     },
      //     overs(currentOvers) {
      //       const isWide = createCricketStat.extras?.find(
      //         (x) => x.statType.name === "Wide"
      //       );

      //       const isNoBall = createCricketStat.extras?.find(
      //         (x) => x.statType.name === "No Ball"
      //       );

      //       if (isWide || isNoBall) {
      //         return currentOvers;
      //       }

      //       console.log("Updating period overs");
      //       console.log(createCricketStat.ballsBowled.value);
      //       console.log(currentOvers);

      //       if (createCricketStat.ballsBowled.value > currentOvers) {
      //         console.log("Setting new period overs");
      //         return createCricketStat.ballsBowled.value;
      //       } else {
      //         return currentOvers;
      //       }
      //     },
      //   },
      // });

      const newBatsmen = [...batsmen];
      const newStrikeIndex =
        value % 2 ? (strikeIndex === 1 ? 0 : 1) : strikeIndex;

      if (selectedWicket) {
        if (
          ["Bowled", "LBW", "Caught", "Caught & Bowled", "Stumped"].includes(
            selectedWicket
          )
        ) {
          newBatsmen[strikeIndex] = {};
        }

        if (["Run Out", "Retired", "Retired Hurt"].includes(selectedWicket)) {
          const dismissedBatsmanIndex = newBatsmen.findIndex(
            (x) => x.profile.id === dismissedBatsman.id
          );
          if (dismissedBatsmanIndex > -1) {
            newBatsmen[dismissedBatsmanIndex] = {};
          }
        }
      }

      setCricketScoringState({
        batsmen: newBatsmen,
        strikeIndex: newStrikeIndex,
        selectedWicket: "",
        selectedExtras: [],
        selectedFielder: "",
        secondaryFielder: "",
        dismissedBatsman: "",
      });

      updateCache({
        variables: {
          where: { id: fixtureId },
          data: {
            statsCacheState: {
              selectedPeriodId: selectedPeriod.id,
              periods: {
                ...(cachedState?.periods || {}),
                [selectedPeriod.id]: {
                  battingTeam,
                  bowlingTeam,
                  batsmen: newBatsmen,
                  bowler,
                  strikeIndex: newStrikeIndex,
                  currentOverNumber,
                },
              },
            },
          },
        },
        update: (c, { data: { updateFixture } }) => {
          setCricketScoringState({
            cache: updateFixture.statsCacheState,
          });
        },
      });

      // onAddStat();
    },
    onError: (error) => {
      captureException({
        error,
        info: {
          type: "mutation",
          component: "ScoreCricket.RunContainer",
          mutation: "CREATE_STAT",
        },
      });
      console.log(error);
    },
  });

  const submit = () => {
    if (loading) return;

    const batsman = batsmen[strikeIndex];

    if (!batsman?.profile) {
      setOverlay(
        <ErrorNotification
          title={"Invalid batsman"}
          message={
            "Please make sure the on strike batsman is selected and try again"
          }
        />
      );
      return;
    }

    if (!bowler?.profile) {
      setOverlay(
        <ErrorNotification
          title={"Invalid bowler"}
          message={"Please make sure a bowler is selected and try again"}
        />
      );
      return;
    }

    let runsScoredValue = value;

    if (
      selectedExtras?.length &&
      !(selectedExtras.length === 1 && selectedExtras[0] === "No Ball")
    ) {
      runsScoredValue = 0;
    }

    function getExtraValue(x, value) {
      if (x === "Wide") {
        return 1 + value;
      } else if (x === "No Ball") {
        return 1;
      } else if (x === "Bye") {
        return value;
      } else if (x === "Leg Bye") {
        return value;
      }
    }

    const runsScoredStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: battingTeam,
      profile: batsman.profile,
      statType: statTypes.find((s) => s.name === "Runs Scored"),
      value: runsScoredValue,
    });

    const ballsFacedStat = selectedExtras?.includes("Wide")
      ? false
      : makeStat({
          eventId,
          fixtureId,
          fixturePeriodId: selectedPeriod?.id,
          organization: battingTeam,
          profile: batsman.profile,
          statType: statTypes.find((s) => s.name === "Balls Faced"),
          value: 1,
        });

    const ballsBowledStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: bowlingTeam,
      profile: bowler.profile,
      statType: statTypes.find((s) => s.name === "Balls Bowled"),
      value: currentOverNumber + 0.1 * (currentBallLabel + 1),
      stringValue: currentOverNumber + "." + (currentBallLabel + 1),
    });

    const runsConcededStat = makeStat({
      eventId,
      fixtureId,
      fixturePeriodId: selectedPeriod?.id,
      organization: bowlingTeam,
      profile: bowler.profile,
      statType: statTypes.find((s) => s.name === "Runs Conceded"),
      value: runsScoredValue,
    });

    const extraStats = selectedExtras?.length
      ? selectedExtras.map((x) =>
          makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile: bowler.profile,
            statType: statTypes.find((s) => s.name === x),
            value: getExtraValue(x, value),
          })
        )
      : false;

    const wicketStat = selectedWicket
      ? makeStat({
          eventId,
          fixtureId,
          fixturePeriodId: selectedPeriod?.id,
          organization: battingTeam,
          profile:
            selectedWicket === "Run Out" ||
            selectedWicket === "Retired" ||
            selectedWicket === "Retired Hurt"
              ? dismissedBatsman
              : batsman.profile,
          statType: statTypes.find((s) => s.name === selectedWicket),
          value: 1,
        })
      : false;

    const wicketTakenStat =
      selectedWicket &&
      selectedWicket !== "Run Out" &&
      selectedWicket !== "Retired" &&
      selectedWicket !== "Retired Hurt"
        ? makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile: bowler.profile,
            statType: statTypes.find((s) => s.name === "Wicket Taken"),
            value: 0,
          })
        : false;

    const fieldingStat =
      selectedWicket &&
      selectedWicket !== "Bowled" &&
      selectedWicket !== "LBW" &&
      selectedWicket !== "Retired" &&
      selectedWicket !== "Retired Hurt"
        ? makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile:
              selectedWicket === "Caught & Bowled"
                ? bowler.profile
                : selectedFielder,
            statType: statTypes.find((s) => {
              const fieldingStatName =
                selectedWicket === "Caught" ||
                selectedWicket === "Caught & Bowled"
                  ? "Catch"
                  : selectedWicket === "Run Out"
                  ? "Run Out"
                  : "Stumping";

              return s.name === fieldingStatName;
            }),
            value: 0,
          })
        : false;

    const secondaryFieldingStat =
      selectedWicket === "Run Out" && secondaryFielder
        ? makeStat({
            eventId,
            fixtureId,
            fixturePeriodId: selectedPeriod?.id,
            organization: bowlingTeam,
            profile: secondaryFielder,
            statType: statTypes.find((s) => {
              return s.name === "Run Out Stumping";
            }),
            value: 0,
          })
        : false;

    const runsScoredRelationships = [];

    const ballsBowledRelationships = [{ ...runsConcededStat }];

    const wicketRelationships = [];

    if (wicketStat && wicketTakenStat) {
      wicketRelationships.push(wicketTakenStat);
    }

    if (wicketStat && fieldingStat) {
      wicketRelationships.push(fieldingStat);
    }

    if (wicketStat && secondaryFieldingStat) {
      wicketRelationships.push(secondaryFieldingStat);
    }

    if (wicketRelationships.length) {
      wicketStat.relationshipsFrom = {
        create: wicketRelationships.map((r) => ({ to: { create: r } })),
      };
    }

    if (extraStats?.length) {
      extraStats.forEach((xs) => {
        ballsBowledRelationships.push(xs);
      });
    }

    ballsBowledStat.relationshipsFrom = {
      create: ballsBowledRelationships.map((r) => ({ to: { create: r } })),
    };

    if (ballsFacedStat) {
      runsScoredRelationships.push(ballsFacedStat);
    }

    runsScoredRelationships.push(ballsBowledStat);

    if (wicketStat) {
      runsScoredRelationships.push(wicketStat);
    }

    runsScoredStat.relationshipsFrom = {
      create: runsScoredRelationships.map((r) => ({ to: { create: r } })),
    };

    // console.log(wicketTakenStat);
    // return;

    createCricketStat({
      variables: {
        data: runsScoredStat,
      },
    });
  };

  return (
    <div
      className="ScoringApp-cricket__scoring__current-over__body__section__item"
      style={{ width: "25%" }}
      onClick={submit}
    >
      {loading ? <Spinner /> : value}
    </div>
  );
}

export default function CurrentOver() {
  const [
    {
      fixtureId,
      selectedPeriod,
      battingTeam,
      batsmen,
      bowler,
      strikeIndex,
      currentOverNumber,
      setOverlay,
      bowlingTeam,
      selectedExtras = [],
      selectedWicket,
      cache,
      updateCache,
    },
    setCricketScoringState,
  ] = useCricketScoringState();

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
          cricketLiveScoring{
            id
            periodScores{
              id
              runs
              wickets
              overs
            }        
            overStats(periodId:"${selectedPeriod.id}", overNumber:${currentOverNumber}){
                id
                over
                ballsBowled{
                    id
                    value
                }
                ballsFaced{
                    id
                }
                runsScored{
                    id
                    value
                }
                runsConceded{
                    id
                }
                wicket{
                    id
                    statType{
                        id
                        name
                    }
                }
                wicketTaken{
                    id
                }
                extras{
                    id
                    statType{
                        id
                        name
                    }
                }
                fielding{
                    id
                }
            }        
          }
      `,
    }),
    {
      variables: {
        id: fixtureId,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div />;
  }

  if (loading || !data) {
    return (
      <div className="ScoringApp-cricket__scoring__current-over">
        <Spinner />
      </div>
    );
  }

  const overItems = data?.fixture?.cricketLiveScoring?.overStats || [];
  const currentBallLabel = ballsCompleted(overItems);
  const score = selectedPeriod
    ? data?.fixture?.cricketLiveScoring?.periodScores?.find(
        (s) => s.id === selectedPeriod.id
      )
    : false;

  console.log("TOTAL OVERS:", score);
  const totalOvers = Math.ceil(score?.overs || 0);
  const overNumbers = [...Array(totalOvers).keys()];
  // console.log("Overs list:", currentOverNumber);

  // const addStat = (runs) => {};

  return (
    <div className="ScoringApp-cricket__scoring__current-over">
      <div className="ScoringApp-cricket__scoring__current-over__header">
        <NewDropdown
          wrapperClass="ScoringApp-cricket__scoring__current-over__header__selector__wrapper"
          targetWrapperClass="ScoringApp-cricket__scoring__current-over__header__selector__target"
          target={
            <div className="ScoringApp-cricket__scoring__current-over__header__selector">
              <div className="ScoringApp-cricket__scoring__current-over__header__selector__secondary">
                SELECTED OVER
              </div>

              <div className="ScoringApp-cricket__scoring__current-over__header__selector__primary">
                {(currentOverNumber || 0) + "." + currentBallLabel}
                <ChevronDown className="ScoringApp-cricket__scoring__current-over__header__selector__primary__icon" />
              </div>
            </div>
          }
          menu={({ setShowMenu }) => (
            <Card className="ScoringApp-cricket__scoring__current-over__menu">
              {overNumbers.map((o) => (
                <div
                  className="ScoringApp-cricket__scoring__current-over__menu__item"
                  onClick={() => {
                    setCricketScoringState({
                      currentOverNumber: o,
                    });
                    setShowMenu(false);
                  }}
                >
                  {o + 1}
                </div>
              ))}
            </Card>
          )}
          placement={"top-end"}
        />

        <div className="ScoringApp-cricket__scoring__current-over__header__items">
          {overItems.map((o) => (
            <div
              key={o.id}
              className={
                "ScoringApp-cricket__scoring__current-over__header__items__item " +
                (o.extras?.find((x) =>
                  ["Wide", "No Ball"].includes(x.statType.name)
                )
                  ? "muted"
                  : "")
              }
            >
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__tag">
                {o.extras
                  ?.map((x) => extraAbbrevs[x.statType.name])
                  .join("+") || ""}
              </div>
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__run">
                {o.runsScored?.value || 0}
              </div>
              <div className="ScoringApp-cricket__scoring__current-over__header__items__item__wicket">
                {o.wicket?.statType?.name?.toUpperCase() || ""}
              </div>
            </div>
          ))}
        </div>

        <div className="ScoringApp-cricket__scoring__current-over__header__options">
          <div
            className="ScoringApp-cricket__scoring__current-over__header__options__option hover-pointer"
            onClick={() => {
              setCricketScoringState({
                currentOverNumber: currentOverNumber + 1,
                strikeIndex: strikeIndex ? 0 : 1,
              });

              setOverlay(
                <SelectPlayer
                  title="Select Bowler"
                  onSelect={(player) => {
                    setCricketScoringState({
                      bowler: { profile: player.profile },
                    });

                    updateCache({
                      variables: {
                        where: { id: fixtureId },
                        data: {
                          statsCacheState: {
                            selectedPeriodId: selectedPeriod.id,
                            periods: {
                              ...(cache.periods || {}),
                              [selectedPeriod.id]: {
                                battingTeam,
                                bowlingTeam,
                                batsmen,
                                bowler: { profile: player.profile },
                                strikeIndex,
                                currentOverNumber,
                              },
                            },
                          },
                        },
                      },
                      update: (c, { data: { updateFixture } }) => {
                        setCricketScoringState({
                          cache: updateFixture.statsCacheState,
                        });
                      },
                    });

                    setOverlay(false);
                  }}
                  defaultTeam={bowlingTeam}
                  hideTabs={true}
                />
              );
            }}
          >
            Over Complete
          </div>
        </div>
      </div>

      <div className="ScoringApp-cricket__scoring__current-over__body">
        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "25%" }}
        >
          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Wide") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Wide")) {
                setCricketScoringState({
                  selectedExtras: [],
                });
              } else {
                setCricketScoringState({
                  selectedExtras: ["Wide"],
                });
              }
            }}
            style={{ width: "50%" }}
          >
            WIDE
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("No Ball") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("No Ball")) {
                setCricketScoringState({
                  selectedExtras: [],
                });
              } else {
                setCricketScoringState({
                  selectedExtras: ["No Ball"],
                });
              }
            }}
            style={{ width: "50%" }}
          >
            NO BALL
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Bye") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Bye")) {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras].filter(
                      (x) => x !== "Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: [],
                  });
                }
              } else {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras, "Bye"].filter(
                      (x) => x !== "Leg Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: ["Bye"],
                  });
                }
              }
            }}
            style={{ width: "50%" }}
          >
            BYE
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedExtras.includes("Leg Bye") ? "active" : "")
            }
            onClick={() => {
              if (selectedExtras.includes("Leg Bye")) {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras].filter(
                      (x) => x !== "Leg Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: [],
                  });
                }
              } else {
                if (selectedExtras.includes("No Ball")) {
                  setCricketScoringState({
                    selectedExtras: [...selectedExtras, "Leg Bye"].filter(
                      (x) => x !== "Bye"
                    ),
                  });
                } else {
                  setCricketScoringState({
                    selectedExtras: ["Leg Bye"],
                  });
                }
              }
            }}
            style={{ width: "50%" }}
          >
            LEG BYE
          </div>
        </div>

        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "40%" }}
        >
          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Bowled" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Bowled") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Bowled",
                });
              }
            }}
          >
            BOWLED
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "LBW" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "LBW") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "LBW",
                });
              }
            }}
          >
            LBW
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Caught" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Caught") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                });
              } else {
                setOverlay(
                  <SelectPlayer
                    title="Select Catcher"
                    defaultTeam={bowlingTeam}
                    onSelect={(player) => {
                      setCricketScoringState({
                        selectedFielder: player.profile,
                        selectedWicket: "Caught",
                      });
                      setOverlay(false);
                    }}
                    hideTabs={true}
                  />
                );
              }
            }}
          >
            CAUGHT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Caught & Bowled" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Caught & Bowled") {
                setCricketScoringState({
                  selectedWicket: "",
                });
              } else {
                setCricketScoringState({
                  selectedWicket: "Caught & Bowled",
                });
              }
            }}
          >
            C & B
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Stumped" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Stumped") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                });
              } else {
                setOverlay(
                  <SelectPlayer
                    title="Select Wicketkeeper"
                    defaultTeam={bowlingTeam}
                    onSelect={(player) => {
                      setCricketScoringState({
                        selectedFielder: player.profile,
                        selectedWicket: "Stumped",
                      });
                      setOverlay(false);
                    }}
                    hideTabs={true}
                  />
                );
              }
            }}
          >
            STUMPED
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Run Out" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Run Out") {
                setCricketScoringState({
                  selectedWicket: "",
                  selectedFielder: "",
                  secondaryFielder: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(<SelectRunOutType />);
                // setOverlay(
                //   <SelectPlayer
                //     title="Select Fielder"
                //     defaultTeam={bowlingTeam}
                //     onSelect={(player) => {
                //       setCricketScoringState({
                //         selectedFielder: player.profile,
                //       });

                //       setOverlay(
                //         <SelectDismissedBatsman
                //           onSelect={(player) => {
                //             setCricketScoringState({
                //               dismissedBatsman: player.profile,
                //               selectedWicket: "Run Out",
                //             });
                //             setOverlay(false);
                //           }}
                //         />
                //       );
                //     }}
                //     hideTabs={true}
                //   />
                // );
              }
            }}
          >
            RUN OUT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Retired" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Retired") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Retired",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            RETIRED OUT
          </div>

          <div
            className={
              "ScoringApp-cricket__scoring__current-over__body__section__item " +
              (selectedWicket === "Retired Hurt" ? "active" : "")
            }
            style={{ width: "25%" }}
            onClick={() => {
              if (selectedWicket === "Retired Hurt") {
                setCricketScoringState({
                  selectedWicket: "",
                  dismissedBatsman: "",
                });
              } else {
                setOverlay(
                  <SelectDismissedBatsman
                    onSelect={(player) => {
                      setCricketScoringState({
                        dismissedBatsman: player.profile,
                        selectedWicket: "Retired Hurt",
                      });
                      setOverlay(false);
                    }}
                  />
                );
              }
            }}
          >
            RETIRED HURT
          </div>
        </div>

        <div
          className="ScoringApp-cricket__scoring__current-over__body__section"
          style={{ width: "35%" }}
        >
          <RunContainer
            value={0}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={1}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={2}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={3}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={4}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={5}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <RunContainer
            value={6}
            currentBallLabel={currentBallLabel}
            cricketLiveScoring={data?.fixture?.cricketLiveScoring}
          />

          <div
            className="ScoringApp-cricket__scoring__current-over__body__section__item"
            style={{ width: "25%" }}
          >
            ...
          </div>
        </div>
      </div>
    </div>
  );
}
